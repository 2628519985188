$(document).ready(function() {
    $(document).on('change', '.custom-file-input', function() {
        $(this).parent().find('label').text($(this).val().replace('C:\\fakepath\\', ''));
    });

    $('form').on('click', '[data-copy="true"]', function () {
        var target = $(this).data('target');

        $(target).prop('disabled', false);
        $(target).select();
        document.execCommand("copy");
        $(target).prop('disabled', true);
    })
    .on('click', '[data-allow-edit="true"]', function () {
        var target = $(this).data('target');

        $(target).prop('disabled', false);
    })
    .on('click', 'button[data-collection-add]', function () {
        var list = $($(this).data('target'));

        // Try to find the counter of the list or use the length of the list
        var counter = list.data('widget-counter') | list.children().length;

        // grab the prototype template
        var newWidget = list.attr('data-prototype');
        // replace the "__name__" used in the id and name of the prototype
        // with a number that's unique to your emails
        // end name attribute looks like name="contact[emails][2]"
        newWidget = newWidget.replace(/__name__/g, counter);
        // Increase the counter
        counter++;
        // And store it, the length cannot be used if deleting widgets is allowed
        list.data('widget-counter', counter);

        // create a new list element and add it to the list
        var newElem = jQuery(list.attr('data-widget-tags')).html(newWidget);
        newElem.appendTo(list);

        $(newElem).find('input').trigger('click');
    })
    .on('click', 'button[data-collection-remove]', function () {
        $(this).closest('li').remove();
    });

    $('#sidebarToggle, #sidebarToggleTop').on('click', function () {
        if ($('body').hasClass('sidebar-toggled') && $('#accordionSidebar').hasClass('toggled')) {
            Cookies.set('sidebar-toggled', true);
        } else {
            Cookies.set('sidebar-toggled', false);
        }
    });

    $('main').on('submit', 'form[data-side-loader]', function () {
        $('#side-loader-info').html($(this).data('side-loader'));
        $('#side-loader').addClass('d-flex');
    });
});
